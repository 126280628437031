<div id="sidebar">
  <div class="nav">
    <ng-container *ngFor="let sideBarItem of menuModel.sideMenuOptions">
      <button type="button" role="button" class="btn btn-navigate"
              [routerLink]="sideBarItem.link"
              [routerLinkActiveOptions]="{exact: sideBarItem.routerLinkExact}"
              routerLinkActive="active">
        <div class="d-flex align-items-center gap-2">
          <i-tabler *ngIf="sideBarItem?.icon" name="{{sideBarItem.icon}}" class="icon"></i-tabler>
          <span
            class="label d-inline d-md-none d-lg-inline">{{ 'COMPONENT.SIDE_MENU.' + sideBarItem.title | translate }}</span>
        </div>
      </button>
    </ng-container>
  </div>

  <div class="version">v{{ applicationModel.appVersion }}</div>
</div>