import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ApplicationActions } from '@module/shared/storage/application/application.actions';
import { ApplicationConfig } from '@config/application.config';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LanguageVersion } from '@data/common/LanguageVersion';

export interface ApplicationStateModel {
  language: LanguageVersion;
}

@State<ApplicationStateModel>({
  name: 'application'
})
@Injectable()
export class ApplicationState implements NgxsOnInit {
  constructor(private translateService: TranslateService,
              private bsLocaleService: BsLocaleService) {
  }

  ngxsOnInit(ctx: StateContext<ApplicationStateModel>) {
    this.setDefaultLanguage(ctx);
  }

  @Action(ApplicationActions.ChangeLanguage)
  changeLanguage(ctx: StateContext<ApplicationStateModel>, action: ApplicationActions.ChangeLanguage) {
    const { language } = action;

    setTimeout(() => {
      this.translateService.use(language);
      this.bsLocaleService.use(language);
    });

    ctx.patchState({ language: action.language });
  }

  private setDefaultLanguage(ctx: StateContext<ApplicationStateModel>): void {
    this.translateService.setDefaultLang(ApplicationConfig.defaultLanguage);
    const language: LanguageVersion = ctx.getState().language || ApplicationConfig.defaultLanguage;

    ctx.dispatch(new ApplicationActions.ChangeLanguage(language));
  }
}