import { Pipe, PipeTransform } from '@angular/core';
import { IFullName } from '@data/interface/IFullName';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(value: IFullName): string {
    return value?.firstName ? `${ value.firstName } ${ value?.lastName }` : '-';
  }

}
