import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Constant } from '@common/constant';
import { catchError } from 'rxjs/operators';
import { NotificationUtil } from '@module/shared/util/notification.util';
import { Store } from '@ngxs/store';
import { AuthActions } from '@module/shared/storage/auth/auth.actions';
import { IErrorResponse } from '@data/interface/IErrorResponse';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store,
              private notificationUtil: NotificationUtil) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: any, caught: Observable<HttpEvent<any>>) => {
          if (error instanceof HttpErrorResponse) {
            const { status } = error;

            if (request.headers.has(Constant.HTTP_IGNORE_ERROR)) {
              return throwError({
                handled: false,
                error: error.error,
                customErrorCode: false
              } as IErrorResponse);
            }

            if (status === 403) {
              this.store.dispatch(AuthActions.Logout);
              this.showMessage('ERROR.FORBIDDEN');
              return throwError({ handled: true, error: error?.error } as IErrorResponse);
            } else if (status === 401) {
              return throwError({ handled: true, error: error?.error } as IErrorResponse);
            } else if ([ 400, 422 ].includes(status) && (error.error?.errorCode || error.error?.errorDescription)) {
              const { errorCode, errorDescription } = error.error;
              const customHandleErrorCodes = request.headers.getAll(Constant.HTTP_CUSTOM_HANDLE_ERROR_CODES) || [];

              if (customHandleErrorCodes.includes(errorCode)) {
                return throwError({
                  handled: false,
                  error: error.error,
                  customErrorCode: true
                } as IErrorResponse);
              }

              if (errorDescription) {
                this.showMessage(errorDescription, { errorCode });
              } else {
                this.showMessage('ERROR.GENERAL_ERROR', { errorCode });
              }

              return throwError({ handled: true, error: error.error } as IErrorResponse);
            } else {
              this.showMessage('ERROR.GENERAL_ERROR');
            }
          }

          return throwError({ handled: true, error: error.error } as IErrorResponse);
        }));
  }

  private showMessage(key: string, params: any = {}) {
    this.notificationUtil.error(key, params);
  }
}
