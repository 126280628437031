import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constant } from '@common/constant';
import { ObjectUtil } from '@module/shared/util/object.util';
import { IRequestOptions } from '@data/interface/IRequestOptions';

@Injectable({
  providedIn: 'root'
})
export class HttpUtil {

  constructor(private httpClient: HttpClient) {
  }

  public get(url: string, parameters: unknown = {}, options?: IRequestOptions): Observable<any> {
    const params: HttpParams = this.buildParams(parameters);
    const headers: HttpHeaders = this.buildHeaders(options);

    return this.httpClient.get(url, {
      params: params,
      headers,
      responseType: (options?.responseType as any) || 'json'
    });
  }

  public post(url: string, data: any, options?: IRequestOptions, parameters: unknown = {}): Observable<any> {
    const params: HttpParams = this.buildParams(parameters);
    const headers: HttpHeaders = this.buildHeaders(options);

    return this.httpClient.post(url,
      (data instanceof HttpParams || data instanceof FormData) ? data : ObjectUtil.instanceToPlain(data), {
        headers,
        params
      });
  }

  public upload(url: string, data: any, options?: IRequestOptions): Observable<any> {
    const headers: HttpHeaders = this.buildHeaders(options);

    return this.httpClient.post(url, data, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
  }

  public delete(url: string, options?: IRequestOptions): Observable<any> {
    const headers: HttpHeaders = this.buildHeaders(options);

    return this.httpClient.delete(url, { headers });
  }

  public put(url: string, data: any, options?: IRequestOptions): Observable<any> {
    const headers: HttpHeaders = this.buildHeaders(options);

    return this.httpClient.put(url, ObjectUtil.instanceToPlain(data), { headers });
  }

  public patch(url: string, data: any, options?: IRequestOptions): Observable<any> {
    const headers: HttpHeaders = this.buildHeaders(options);

    return this.httpClient.patch(url, ObjectUtil.instanceToPlain(data), { headers });
  }

  private buildHeaders(options: IRequestOptions): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();

    if (options?.customHandleErrorCodes) {
      headers = headers.set(Constant.HTTP_CUSTOM_HANDLE_ERROR_CODES, options.customHandleErrorCodes);
    }

    if (options?.silentMode) {
      headers = headers.set(Constant.HTTP_SILENT_MODE, '');
    }

    if (options?.ignoreError) {
      headers = headers.set(Constant.HTTP_IGNORE_ERROR, '');
    }

    if (options?.headers) {
      options.headers.forEach((value: string, key: string) => {
        headers = headers.set(key, value);
      });
    }

    return headers;
  }

  private buildParams(params: any = {}): HttpParams {
    const obj = ObjectUtil.instanceToPlain(params, { exposeUnsetFields: false });

    Object.keys(obj).forEach((key: string) => {
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].join(',');
      }
    });

    return new HttpParams({ fromObject: obj });
  }

}
