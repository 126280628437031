import { LanguageVersion } from '@data/common/LanguageVersion';
import { EnvironmentType } from '@data/common/EnvironmentType';

export const environment = {
  name: EnvironmentType.DEV,
  production: false,
  apiUrl: 'https://refhealth-d.weill.cornell.edu/api',
  defaultLanguage: LanguageVersion.EN,
  cognito: {
    userPoolId: 'us-east-1_NAHfwcSPL',
    userPoolWebClientId: '1dnrlj8m105s1g60sunnqbefae',
    region: 'us-east-1'
  }
};
