import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { inject } from '@angular/core';

export abstract class BasicModal<T> {
  private onClose: Subject<T> = new Subject();

  public close$: Observable<T> = this.onClose.asObservable();

  protected bsModalRef = inject(BsModalRef);

  public ok(value: T): void {
    this.bsModalRef.hide();
    this.bsModalRef.onHidden.subscribe(
      () => {
        this.onClose.next(value);
        this.onClose.complete();
      }
    );
  }

  public cancel(): void {
    this.onClose.error(null);
    this.exit();
  }

  public close(): void {
    this.onClose.error(null);
    this.exit();
  }

  protected exit(): void {
    this.onClose.complete();
    this.bsModalRef.hide();
  }
}