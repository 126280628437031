<header id="header">
  <div class="d-flex align-items-center justify-content-between px-2 h-100">
    <div class="d-flex gap-5 align-items-center">
      <div class="d-md-none" *ngIf="showSidebar">
        <button type="button" class="btn btn-link btn-lg px-2" (click)="toggle()" *ngIf="collapsed">
          <div class="d-flex justify-content-center align-items-center">
            <i-tabler name="menu-2" class="icon"></i-tabler>
          </div>
        </button>

        <div class="sidebar-container" cdkTrapFocus *ngIf="showSidebar">
          <button type="button" class="btn btn-link btn-lg px-2" (click)="toggle()" *ngIf="!collapsed">
            <div class="d-flex justify-content-center align-items-center">
              <i-tabler name="x" class="icon"></i-tabler>
            </div>
          </button>

          <div class="sidebar-position">
            <div class="sidebar" [collapse]="collapsed" [isAnimated]="true">
              <div class="container px-0 pt-3">
                <div class="sidebar-scrollable-view pr-2">
                  <app-side-menu></app-side-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="logo">
        <app-logo></app-logo>
      </div>
    </div>

    <div class="d-flex align-items-center gap-4">
      <span *ngIf="user$ | async as user">{{ user.firstName }} {{ user.lastName }}</span>
      <button type="button" class="btn btn-link btn-lg px-1"
              (click)="logout()">
        <i-tabler name="logout" class="icon"></i-tabler>
      </button>
    </div>
  </div>
</header>


