import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateOrDefault'
})
export class TranslateOrDefaultPipe implements PipeTransform {
  constructor(private translate: TranslateService) {

  }

  transform(key: string, params: any = null, defaultValue: string = ''): string {
    const translated = this.translate.instant(key, params);

    return translated !== key ? translated : defaultValue;
  }

}
