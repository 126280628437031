import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: [ './styleguide.component.scss' ]
})
export class StyleguideComponent implements OnInit {
  public options: any[] = [
    { label: 'Option 1', value: 1 },
    { label: 'Option 2', value: 2 },
    { label: 'Option 3', value: 3 },
    { label: 'Option 4', value: 4 },
    { label: 'Option 5', value: 5 }
  ];

  public selectedValue: number = 5;

  public nonSelectedValue: number;

  constructor() {
  }

  public ngOnInit(): void {
  }
}