import { Injectable } from '@angular/core';
import { HttpUtil } from '@module/shared/util/http.util';
import { from, Observable } from 'rxjs';
import { CognitoAuthService } from '@module/shared/service/cognito-auth.service';
import { TokenDTO } from '@data/auth/TokenDTO';
import { IRequestOptions } from '@data/interface/IRequestOptions';
import { LoggedUserDTO } from '@data/auth/LoggedUserDTO';
import { ApplicationConfig } from '@config/application.config';
import { ObjectUtil } from '@util/object.util';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private httpUtil: HttpUtil,
              private cognitoAuthService: CognitoAuthService) {
  }

  public login(username: string, password: string): Observable<TokenDTO> {
    return from(this.cognitoAuthService.login(username, password));
  }

  public setPassword(username: string, newPassword: string): Observable<TokenDTO> {
    return from(this.cognitoAuthService.setPassword(username, newPassword));
  }


  public getCurrentLoggedUser(): Observable<LoggedUserDTO> {
    const options = { silentMode: true } as IRequestOptions;

    return this.httpUtil.get(`${ ApplicationConfig.apiUrl }/users/me`, undefined, options)
      .pipe(
        map((response: LoggedUserDTO) => ObjectUtil.plainToInstance(LoggedUserDTO, response))
      );
  }

  public refreshToken(token: TokenDTO): Observable<TokenDTO> {
    return from(this.cognitoAuthService.refresh(token));
  }

}
