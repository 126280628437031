import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CastToTypePipe } from './pipe/to-type.pipe';
import { ReadOnlyEntryComponent } from '@module/shared/component/read-only-entry/read-only-entry.component';
import { BackComponent } from '@module/shared/component/back/back.component';
import { RouterModule } from '@angular/router';
import { ConfirmationModalComponent } from '@module/shared/component/modal/confirmation/confirmation-modal.component';
import { LogoComponent } from './component/logo/logo.component';
import { ConcatPipe } from './pipe/concat.pipe';
import { ReplaceEmptyPipe } from './pipe/replace-empty.pipe';
import { LinkifyPipe } from '@module/shared/pipe/linkify.pipe';
import { ConcatEnumPipe } from '@module/shared/pipe/concatEnum.pipe';
import { FullNamePipe } from '@module/shared/pipe/full-name.pipe';
import { DurationPipe } from '@module/shared/pipe/duration.pipe';
import { ToArrayPipe } from '@module/shared/pipe/to-array.pipe';
import { IncludePipe } from '@module/shared/pipe/include.pipe';
import { TranslateOrDefaultPipe } from '@module/shared/pipe/translate-or-default.pipe';
import { HideIfElementEmptyDirective } from './directive/hide-if-element-empty.directive';
import { InArrayPipe } from '@module/shared/pipe/in-array.pipe';
import { InputCurrencyDirective } from '@module/shared/directive/input-currency.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { PrefixPipe } from '@module/shared/pipe/prefix.pipe';
import { BooleanPipe } from '@module/shared/pipe/boolean.pipe';
import { IconModule } from '@module/core/icon/icon.module';
import { ValidationHandlerComponent } from '@module/shared/component/validation-handler/validation-handler.component';

@NgModule({
  declarations: [
    CastToTypePipe,
    ReadOnlyEntryComponent,
    BackComponent,
    ConfirmationModalComponent,
    LogoComponent,
    ConcatPipe,
    ReplaceEmptyPipe,
    LinkifyPipe,
    ConcatEnumPipe,
    FullNamePipe,
    DurationPipe,
    ToArrayPipe,
    IncludePipe,
    TranslateOrDefaultPipe,
    HideIfElementEmptyDirective,
    InArrayPipe,
    InputCurrencyDirective,
    PrefixPipe,
    BooleanPipe,
    ValidationHandlerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    AngularSvgIconModule,
    ReactiveFormsModule,
    IconModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    AngularSvgIconModule,
    CastToTypePipe,
    ConcatPipe,
    ReadOnlyEntryComponent,
    BackComponent,
    LogoComponent,
    ReplaceEmptyPipe,
    LinkifyPipe,
    ConcatEnumPipe,
    FullNamePipe,
    DurationPipe,
    ToArrayPipe,
    IncludePipe,
    TranslateOrDefaultPipe,
    HideIfElementEmptyDirective,
    InArrayPipe,
    InputCurrencyDirective,
    PrefixPipe,
    BooleanPipe,
    ValidationHandlerComponent
  ],
  providers: [
    DecimalPipe,
    FullNamePipe
  ]
})
export class SharedModule {
}
