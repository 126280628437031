<div class="modal-header">
  <ng-container [ngTemplateOutlet]="titleTemplate || defaultTitleTemplate"></ng-container>
</div>

<div class="modal-body d-flex flex-column">
  <ng-container [ngTemplateOutlet]="descriptionTemplate || defaultDescriptionTemplate"></ng-container>
</div>

<div class="modal-footer">
  <button type="button"
          class="btn btn-primary-normal" *ngIf="showCancelButton"
          (click)="close()">{{ cancelButtonText | translate:data }}
  </button>

  <button type="button"
          class="btn btn-primary"
          (click)="ok(true)">{{ confirmButtonText | translate:data }}
  </button>
</div>

<!--Templates-->
<ng-template #defaultTitleTemplate>
  <h4 class="modal-title pull-left" [innerHtml]="title | translate: data"></h4>
</ng-template>

<ng-template #defaultDescriptionTemplate><span [innerHtml]="description | translate: data"></span></ng-template>
