import { Component, TemplateRef } from '@angular/core';
import { BasicModal } from '@module/shared/extender/basic-modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: [ './confirmation-modal.component.scss' ]
})
export class ConfirmationModalComponent extends BasicModal<boolean> {

  public title: string;

  public titleTemplate: TemplateRef<any>;

  public description: string;

  public descriptionTemplate: TemplateRef<any>;

  public confirmButtonText: string = 'COMMON.CONFIRM';

  public cancelButtonText: string = 'COMMON.CANCEL';

  public showCancelButton: boolean = true;

  public data: any = {};
}
