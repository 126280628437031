import { Injectable } from '@angular/core';

interface MenuItem {
  icon?: string;

  title: string;

  link?: string;

  routerLinkExact?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MenuModel {
  constructor() {
  }

  private menuOptions: MenuItem[] = [
    // { icon: 'layout-grid', title: 'DASHBOARD', link: '/', routerLinkExact: true },
    { icon: 'users', title: 'USER', link: '/users', routerLinkExact: false },
    // { icon: 'folders', title: 'GROUP', link: '/groups', routerLinkExact: false },
    // { icon: 'user-question', title: 'REQUEST', link: '/requests', routerLinkExact: false },
    { icon: 'clipboard-text', title: 'SURVEY', link: '/surveys', routerLinkExact: false },
    // { icon: 'message', title: 'TIP', link: '/tips', routerLinkExact: false }
  ];

  public get sideMenuOptions(): MenuItem[] {
    return this.menuOptions;
  }
}
