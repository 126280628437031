import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { ApplicationConfig } from '@config/application.config';
import { TokenDTO } from '@data/auth/TokenDTO';
import { AuthErrorCode } from '@data/auth/AuthErrorCode';

@Injectable({
  providedIn: 'root'
})
export class CognitoAuthService {
  private cognitoUser: CognitoUser;

  public login(email: string, password: string): Promise<TokenDTO> {
    const authenticationDetails: AuthenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    this.cognitoUser = this.getCognitoUser(email);

    return new Promise((resolve, reject) => {
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session: CognitoUserSession) => resolve(this.buildToken(session, email)),
        onFailure: (error: any) => reject(error),
        newPasswordRequired: () => reject(new Error(AuthErrorCode.NEW_PASSWORD_REQUIRED))
      });
    });
  }

  public setPassword(email: string, newPassword: string): Promise<TokenDTO> {
    return new Promise((resolve, reject) => {
      this.cognitoUser.completeNewPasswordChallenge(newPassword, undefined, {
        onSuccess: (session: CognitoUserSession) => resolve(this.buildToken(session, email)),
        onFailure: (error: any) => reject(error)
      });
    });
  }

  public refresh(token: TokenDTO): Promise<TokenDTO> {
    return new Promise((resolve, reject) => {
      let cognitoUser: CognitoUser = this.getCognitoUser(token.email);

      cognitoUser?.refreshSession(new CognitoRefreshToken({ RefreshToken: token.refreshToken }), (error: any, session: CognitoUserSession) => {
        if (!error) {
          return resolve(this.buildToken(session, token.email));
        } else {
          return reject();
        }
      });
    });
  }

  private getCognitoUser(email: string): CognitoUser {
    return new CognitoUser({
      Username: email,
      Pool: this.getPool()
    });
  }

  private getPool(): CognitoUserPool {
    return new CognitoUserPool({
      UserPoolId: ApplicationConfig.cognito.userPoolId,
      ClientId: ApplicationConfig.cognito.userPoolWebClientId
    });
  }

  private buildToken(session: CognitoUserSession, email: string): TokenDTO {
    const token: TokenDTO = new TokenDTO();

    token.accessToken = session.getIdToken().getJwtToken();
    token.refreshToken = session.getRefreshToken().getToken();
    token.email = email;

    return token;
  }
}

