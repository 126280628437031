import { Selector } from '@ngxs/store';
import { AuthState, AuthStateModel } from '@module/shared/storage/auth/auth.state';
import { TokenDTO } from '@data/auth/TokenDTO';

export class AuthSelectors {
  @Selector([ AuthState ])
  static getCurrentLogged(state: AuthStateModel) {
    return state.loggedUser;
  }

  @Selector([ AuthState ])
  static isLogging(state: AuthStateModel) {
    return state.isLogging;
  }

  @Selector([ AuthState ])
  static username(state: AuthStateModel) {
    return state.username;
  }

  @Selector([ AuthState ])
  static token(state: AuthStateModel): TokenDTO {
    return state.token;
  }

  @Selector([ AuthState ])
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }
}