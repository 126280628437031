import { LanguageVersion } from '@data/common/LanguageVersion';

export namespace ApplicationActions {
  export class ChangeLanguage {
    static readonly type = '[Application] Change language';

    constructor(public language: LanguageVersion) {

    }
  }
}