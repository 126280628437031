import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  template: `
    <a [routerLink]="['']">
      <img src="assets/image/logo.svg">
    </a>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
