import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StyleguideComponent } from 'app/component/styleguide/styleguide.component';
import { AuthGuard } from '@module/shared/guard/auth.guard';
import { MainLayoutComponent } from '@module/core/layout/component/main-layout/main-layout.component';
import { PreAuthLayoutComponent } from '@module/core/layout/component/pre-auth-layout/pre-auth-layout.component';

const routes: Routes = [
  {
    path: 'auth', component: PreAuthLayoutComponent, children: [
      {
        path: '',
        loadChildren: () => import('./module/page/auth/auth-page.module').then(m => m.AuthPageModule)
      }
    ]
  },
  { path: 'styleguide', component: StyleguideComponent },
  { path: 'error', loadChildren: () => import('./module/page/error/error-page.module').then(m => m.ErrorPageModule) },
  {
    path: '', component: MainLayoutComponent, canActivate: [ AuthGuard ],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./module/page/user/user-page.module').then(m => m.UserPageModule)
      },
      {
        path: 'groups',
        loadChildren: () => import('./module/page/group/group-page.module').then(m => m.GroupPageModule)
      },
      {
        path: 'requests',
        loadChildren: () => import('./module/page/request/request-page.module').then(m => m.RequestPageModule)
      },
      {
        path: 'surveys',
        loadChildren: () => import('./module/page/survey/survey-page.module').then(m => m.SurveyPageModule)
      },
      {
        path: 'tips',
        loadChildren: () => import('./module/page/tip/tip-page.module').then(m => m.TipPageModule)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'users'
      },
      {
        path: '**', redirectTo: 'error/not-found'
      }
    ]
  },
  { path: '**', redirectTo: 'error/not-found' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
