import { environment } from 'environments/environment';
import { IApplicationConfigDefinition } from '@data/common/IApplicationConfigDefinition';

export const ApplicationConfig: IApplicationConfigDefinition = {
  applicationOwner: 'Apzumi',
  applicationName: 'Weill Cornell Medicine',
  version: '0.1.0 (2024-01-19)',
  environmentName: environment.name,
  production: environment.production,
  apiUrl: environment.apiUrl,
  cognito: environment.cognito,
  defaultLanguage: environment.defaultLanguage
};
