import { Pipe, PipeTransform } from '@angular/core';
import { TranslateUtil } from '@module/shared/util/translate.util';

@Pipe({
  name: 'concatEnum'
})
export class ConcatEnumPipe implements PipeTransform {

  constructor(private translateUtil: TranslateUtil) {
  }

  transform(value: any[], prefix: string, field: string = null, separator: string = ', '): string {
    return value?.map(s => this.translateUtil.translate(prefix + '.' + (field ? s[field] : s), {}))?.join(separator);
  }

}
