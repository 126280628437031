import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@module/shared/storage/auth/auth.selectors';
import { ApplicationConfig } from '@config/application.config';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

  constructor(private store: Store) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url } = request;
    const token = this.store.selectSnapshot(AuthSelectors.token);

    if (token && this.isAuthenticationRequired(url)) {
      return next.handle(request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token.accessToken
        }
      }));
    }

    return next.handle(request);
  }

  private isAuthenticationRequired(url: string): boolean {
    return url.includes(ApplicationConfig.apiUrl);
  }
}
