import { TokenDTO } from '@data/auth/TokenDTO';

export namespace AuthActions {
  export class Login {
    static readonly type = '[Auth] Log in';

    constructor(public username: string, public password: string) {

    }
  }

  export class SetPassword {
    static readonly type = '[Auth] Set Password';

    constructor(public newPassword: string) {

    }
  }

  export class GetCurrentLogged {
    static readonly type = '[Auth] Get Current Logged';

    constructor() {

    }
  }

  export class SaveToken {
    static readonly type = '[Auth] Update token';

    constructor(public token: TokenDTO) {

    }
  }

  export class RefreshToken {
    static readonly type = '[Auth] Refresh token';

    constructor() {
    }
  }

  export class Logout {
    static readonly type = '[Auth] Log out';

    constructor() {
    }
  }
}