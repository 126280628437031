import {
  ClassConstructor,
  ClassTransformOptions,
  instanceToPlain,
  plainToClassFromExist,
  plainToInstance
} from 'class-transformer';
import { flatten, unflatten } from 'flat';
import { assign, cloneDeep, mapValues, omit, pick } from 'lodash-es';
import { OptionItem } from '@data/common/OptionItem';

export class ObjectUtil {

  constructor() {
  }

  public static plainToInstance<T, V>(cls: ClassConstructor<T>, obj: V, options?: ClassTransformOptions): T {
    return plainToInstance<T, V>(cls, obj as V, options);
  }

  public static instanceToPlain<V>(obj: V, options?: ClassTransformOptions): any {
    return instanceToPlain(obj, options);
  }

  public static plainToInstanceArray<T, V>(cls: ClassConstructor<T>, obj: V[], options?: ClassTransformOptions): T[] {
    return plainToInstance<T, V>(cls, obj as V[], options);
  }

  public static plainToInstanceFromExist<T, V>(clsObject: T, obj: V, options?: ClassTransformOptions): T {
    return plainToClassFromExist<T, V>(clsObject as T, obj as V, options);
  }

  public static flatten(obj: unknown): unknown {
    return flatten(obj);
  }

  public static unflatten<T>(cls: ClassConstructor<T>, obj: unknown): T {
    return ObjectUtil.plainToInstance(cls, unflatten(obj));
  }

  public static mergeToTargetPropertiesOnly<T>(target: T, source: unknown): T {
    const obj: T = cloneDeep(target);
    const pickedProperties = pick(source, Object.keys(target));

    assign(obj, pickedProperties);

    return obj;
  }

  public static createEnumSelectOptions<T, V>(enumClass: V, enumLangKey: string): OptionItem<T>[] {
    return Object.keys(enumClass).map((key: string) => {
      const option: OptionItem<T> = new OptionItem<T>();

      option.label = `ENUM.${ enumLangKey }.${ key }`;
      option.value = enumClass[key] as T;

      return option;
    });
  }

  public static isEqual(obj: unknown, comparedObj: unknown): boolean {
    return JSON.stringify(obj) === JSON.stringify(comparedObj);
  }

  public static removeProperty(obj: unknown, propertyName: string): unknown {
    if (Array.isArray(obj)) {
      return obj.map(item => ObjectUtil.removeProperty(item, propertyName));
    }

    if (typeof obj === 'object' && obj !== null && obj.hasOwnProperty(propertyName)) {
      const cleanedObj = omit(obj, propertyName);
      return mapValues(cleanedObj, value => ObjectUtil.removeProperty(value, propertyName));
    }

    return obj;
  }
}
