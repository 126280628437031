import { Component, Input } from '@angular/core';
import { MenuModel } from 'app/model/menu.model';
import { ApplicationModel } from 'app/model/application.model';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: [ './side-menu.component.scss' ]
})
export class SideMenuComponent {
  @Input() public menuCollapse: boolean = false;

  constructor(public readonly menuModel: MenuModel,
              public readonly applicationModel: ApplicationModel) {
  }
}
