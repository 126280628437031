<div id="layout">
  <div class="container-fluid px-0 position-relative">
    <div class="top-bar">
      <div class="container px-0">
        <app-top-bar></app-top-bar>
      </div>
    </div>
  </div>

  <div class="container px-0">
    <div class="content d-flex">
      <div class="sidebar-container bg-white pt-3 pr-1 pr-lg-3 pr-xl-4" *ngIf="sideMenuVisible">
        <app-side-menu></app-side-menu>
      </div>

      <main>
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>

</div>


